<template>
    <ValidationObserver ref="createBlackout" v-slot="{ invalid }" >        
        <b-form @submit.prevent="save">
            <b-row>
                <b-col md="3">
                    <ValidationProvider rules="required" name="nombre">
                        <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                            <b-form-input                                
                                class="form-control"
                                type="text"
                                v-model="name"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>   
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
               
            <b-col md="3">
          <div class="float-left pt-1">
            <b-form-checkbox
              v-model="status"
              :value="true"
              class="custom-control-success"
            >
              Activo
            </b-form-checkbox>
          </div>
        </b-col>

        
            <b-col md="3">
          <div class="float-left pt-1">
            <b-form-checkbox
              v-model="highseason"
              
              class="custom-control-success"
            >
              Temporada alta
            </b-form-checkbox>
          </div>
        </b-col>
        
                <b-col md="3" >                                                    
                    <b-button  variant="primary" class="btn-add" @click="addNewInputDate">
                        <feather-icon icon="PlusIcon" size="16" /> Nuevas fechas
                    </b-button>                   
                </b-col>
            </b-row>
            <b-row v-for="(date, index) in rangeDates" :key="date.uuid">
                 <b-col md="3">                     
                    <ValidationProvider rules="required" name="Fechas">
                        <b-form-group label="Fechas" slot-scope="{ valid, errors }">
                            
                            <flat-pickr
                                :config="{ mode: 'range', minDate: 'today'}"
                                class="form-control"
                                v-model="date.range"                                
                                :state="errors[0] ? false : (valid ? true : null)"
								@input="setRange(date)"
                            />    
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>                   
                </b-col>                
                <b-col md="3" v-if="index > 0">                                                    
                    <b-button  variant="danger" class="btn-borrar" @click="deleteInputDate(date.uuid)">
                        <feather-icon icon="TrashIcon" size="16" /> 
                    </b-button>                   
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="float-right">
                        <b-button   type="submit" class="btn-block" variant="primary"  :disabled="invalid || isSavingBlackout"                        
                        > <b-spinner small v-if="isSavingBlackout"/> Guardar
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            
		</b-form>
    </ValidationObserver>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import {  showAlertMessage, stringAleatorio, toJson } from '@/helpers/helpers' 
import {  createArrayDates, removeDatesDuplicated } from '@/helpers/fivesClubHelper' 
import flatPickr from 'vue-flatpickr-component'

export default {
 
  data() {
    return {    
        name:'',
        dateStart: null,
        dateEnd: null,
        status: true,
        highseason:true,
        isSavingBlackout: false,
        rangeDates:[
            {               
               range: '',
               dateStart: null,
               dateEnd: null,               
               uuid: 'f1580ece-c4b2-11ec-9d64-0242ac120002'  
            }
        ]

    }
  },
  components: {    
	flatPickr
  },
  computed: {   
    ...mapState('auth',['user']),    
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['saveBlackout','fetchBlackouts']),
    ...mapMutations('fivesClubCatalogs', ['setBlackouts']),
    async save(){   
        this.isSavingBlackout = true   
        const { newDates, thereAreDuplicates } = removeDatesDuplicated( this.rangeDates )
        if(thereAreDuplicates){
            showAlertMessage( 'Ok', 'InfoIcon', 'Se han removido fechas duplicadas', 'danger', 4000, 'bottom-right')
        }
        this.rangeDates = newDates  //remuevo duplicados        
        const payload = {
            idUser: this.user.idUser,
            name: this.name,
            dates: createArrayDates(this.rangeDates),
            highseason:Boolean(this.highseason),
            status: Boolean(this.status),
        }             
        
        const { status, message } = await this.saveBlackout( payload ) // saving in backend

        if(status){
            showAlertMessage( 'Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right')
            Object.assign( this.$data, this.$options.data() ) 
            this.$refs.createBlackout.reset() //reset form
            //vuelvo a pedir la info del back
            const blackouts  = await this.fetchBlackouts()
            this.setBlackouts(blackouts)
            this.$emit('set-tab-index', 0)            
        }

        this.isSavingBlackout = false 
    },
    addNewInputDate(){
        this.rangeDates.push({range: '', dateStart: null, dateEnd: null,  uuid: stringAleatorio() })
    },
    deleteInputDate(uuid){
        this.rangeDates = this.rangeDates.filter( date => date.uuid !== uuid)
    },
    setRange( date ){
        const { range, uuid } = date
        const idx = toJson( this.rangeDates.findIndex( date => date.uuid === uuid))
		if( range.includes('to') ){
			const splitdate = range.split(' to ')
            this.rangeDates[idx].dateStart = splitdate[0]
            this.rangeDates[idx].dateEnd = splitdate[1]             		            
        } else {
            this.rangeDates[idx].dateStart = ''
            this.rangeDates[idx].dateEnd = ''
            this.rangeDates[idx].range = ''            
        }
    }

  },
};
</script>
<style scoped>
.custom-control {    
    margin-block-start: 1.5rem;
}
.btn-add{
    margin-block-start: 1.5rem;
}
.btn-borrar{
    margin-block-start: 1.5rem;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>