<template>
  <b-card>
    <Blackouts :isLoadingBlackouts="isLoadingBlackouts" @reload-blackouts="init"/>
  </b-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex"
import Blackouts from '@/modules/fivesClub/components/catalogs/blackouts/Blackouts'


export default {
  components:{
    Blackouts,
  },
  async created() {
    await this.init()
  },
  data() {
    return {
      isLoadingBlackouts:false
    };
  },
  computed: {
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchBlackouts']),
    ...mapMutations('fivesClubCatalogs',['setBlackouts']),
    async init(){
      this.isLoadingBlackouts=true
      const blackouts = await this.fetchBlackouts()
      this.setBlackouts( blackouts )
      this.isLoadingBlackouts=false
    }
  },
};
</script>
