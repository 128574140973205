<template>
    <ValidationObserver ref="createBlackout" v-slot="{ invalid }" v-if="selectedBlackout">
        <b-form @submit.prevent="update">
            <b-row>
                <b-col md="12">
                    <b-alert variant="warning" show v-if="isWaitingAuth">
                        <div class="alert-body text-center">
                            <span> Pendiente por autorizar </span>
                        </div>
                    </b-alert>
                </b-col>
                <b-col md="3">
                    <ValidationProvider rules="required" name="nombre">
                        <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                            <b-form-input
                                class="form-control"
                                type="text"
                                v-model="selectedBlackout.name"
                                :disabled="isWaitingAuth"
                                :state="errors[0] ? false : (valid ? true : null)"
                            />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="3">
                    <div class="float-left pt-1">
                        <b-form-checkbox
                            v-model="selectedBlackout.status"
                            :disabled="isWaitingAuth"
                            :value="true"
                            class="custom-control-success"
                        > Activo
                        </b-form-checkbox>
                    </div>
                </b-col>
                <b-col md="3">
                    <div class="float-left pt-1">
                        <b-form-checkbox
                            v-model="selectedBlackout.highseason"
                            :disabled="isWaitingAuth"
                            class="custom-control-success"
                        > Temporada alta
                        </b-form-checkbox>
                    </div>
                </b-col>

                <b-col md="3" >
                    <b-button
                        v-if="!isWaitingAuth"
                        variant="primary"
                        class="btn-add"
                        :disabled="isSavingBlackout"
                        @click="addNewInputDate"
                    >
                        <feather-icon icon="PlusIcon" size="16" /> Nuevas fechas
                    </b-button>
                </b-col>
            </b-row>

            <b-row v-for="(date, index) in selectedBlackout.datesBlackout" :key="date.uuid">
                <b-col md="3">
                    <ValidationProvider rules="required" name="Fechas">
                        <b-form-group label="Fechas" slot-scope="{ valid, errors }">
                            <flat-pickr
                                :config="{ mode: 'range'}"
                                class="form-control"
                                v-model="date.range"
                                :disabled="isWaitingAuth"
                                :state="errors[0] ? false : (valid ? true : null)"
                                @input="setRange(date)"
                            />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="3" v-if="index > 0 && !isWaitingAuth">
                    <b-button
                        variant="danger"
                        class="btn-borrar"
                        @click="deleteInputDate(date.uuid)"
                        v-can="'fivesclub_blackouts_show_blackouts_list_action_button_delete'"
                    >
                        <feather-icon icon="TrashIcon" size="16" />
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="float-right">
                        <b-button
                            v-if="!isWaitingAuth"
                            type="submit"
                            class="btn-block"
                            variant="primary"
                            :disabled="invalid || isSavingBlackout"
                        >
                            <b-spinner small v-if="isSavingBlackout"/> Actualizar
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-form>
    </ValidationObserver>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import * as moment from 'moment'
import {  showAlertMessage, stringAleatorio, toJson } from '@/helpers/helpers'
import {  createArrayDates, removeDatesDuplicated } from '@/helpers/fivesClubHelper'
import flatPickr from 'vue-flatpickr-component'

export default {

data() {
    return {
        isSavingBlackout: false
    }
},
components: {
    flatPickr
},
computed: {
    ...mapState('fivesClubCatalogs',['selectedBlackout']),
    ...mapState('auth',['user']),
    isWaitingAuth(){
        return this.selectedBlackout?.temporalDataStatus || this.selectedBlackout?.datesBlackout?.some(dates => !!dates.temporalDataStatus)
    }
},
methods: {
    ...mapActions('fivesClubCatalogs', ['updateBlackout']),
    ...mapMutations('fivesClubCatalogs',['setSelectedBlackout']),
    async update(){
        this.isSavingBlackout = true
        const { newDates, thereAreDuplicates } = removeDatesDuplicated( this.selectedBlackout.datesBlackout)
        if(thereAreDuplicates){
            showAlertMessage( 'Ok', 'InfoIcon', 'Se han removido fechas duplicadas', 'danger', 4000, 'bottom-right')
        }
        this.selectedBlackout.datesBlackout = newDates  //remuevo duplicados
        const payload = {
            idUser: this.user.idUser,
            id: this.selectedBlackout.id,
            name: this.selectedBlackout.name,
            dates: this.selectedBlackout.datesBlackout,
            highseason:Boolean(this.selectedBlackout.highseason),
            status: Boolean(this.selectedBlackout.status),
        }
        const { status, message } = await this.updateBlackout( payload ) // updateBlackout in backend

        if(status){
            showAlertMessage( 'Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right')
            this.setSelectedBlackout(null)
            this.$emit('get-blackouts')
            this.$emit('set-tab-index', 0)
        }

        this.isSavingBlackout = false
    },
    addNewInputDate(){
        this.selectedBlackout.datesBlackout.push({range: '', dateStart: null, dateEnd: null,  uuid: stringAleatorio() })
    },
    deleteInputDate(uuid){
        this.selectedBlackout.datesBlackout = this.selectedBlackout.datesBlackout.filter( date => date.uuid !== uuid)
    },
    setRange( date ){
        const { range, uuid } = date
        const idx = toJson( this.selectedBlackout.datesBlackout.findIndex( date => date.uuid === uuid))
        if( range.includes('to') ){
            const splitdate = range.split(' to ')
            this.selectedBlackout.datesBlackout[idx].dateStart = splitdate[0]
            this.selectedBlackout.datesBlackout[idx].dateEnd = splitdate[1]
        } else {
            this.selectedBlackout.datesBlackout[idx].dateStart = ''
            this.selectedBlackout.datesBlackout[idx].dateEnd = ''
            this.selectedBlackout.datesBlackout[idx].range = ''
        }
    }
},
};
</script>
<style scoped>
.custom-control {
    margin-block-start: 1.5rem;
}
.btn-add{
    margin-block-start: 1.5rem;
}
.btn-borrar{
    margin-block-start: 1.5rem;
}
</style>