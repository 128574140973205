<template>
    <b-tabs v-model="tabIndex">
      <b-tab @click="clickTab" v-if="can('fivesclub_blackouts_show_blackouts_list')">
        <template #title >
          <div> Blackouts </div>
        </template>
        <ListBlackouts
          :isLoadingBlackouts="isLoadingBlackouts"
          @set-tab-index="setTabIndex"
          @get-blackouts="getBlackouts"
        />
      </b-tab>

      <b-tab title="Crear"  @click="clickTab" v-if="can('fivesclub_blackouts_show_blackouts_add_blackout')">
        <BlackoutCreate @set-tab-index="setTabIndex"/>
      </b-tab>

      <b-tab>
        <template #title >
          <div :class="selectedBlackout ? '' : 'd-none'">
            Actualizar
          </div>
        </template>
        <BlackoutEdit @set-tab-index="setTabIndex" @get-blackouts="getBlackouts"/>
      </b-tab>

    </b-tabs>
</template>

<script>
import { mapState,mapMutations } from "vuex"
import ListBlackouts from '@/modules/fivesClub/components/catalogs/blackouts/ListBlackouts'
import BlackoutCreate from '@/modules/fivesClub/components/catalogs/blackouts/BlackoutCreate'
import BlackoutEdit from '@/modules/fivesClub/components/catalogs/blackouts/BlackoutEdit'
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [acl],
  components:{
    ListBlackouts,
    BlackoutCreate,
    BlackoutEdit
  },
  props: {
    isLoadingBlackouts:{
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  computed: {
    ...mapState('fivesClubCatalogs',['selectedBlackout']),
  },
  methods: {
    ...mapMutations('fivesClubCatalogs',['setSelectedBlackout']),
    setTabIndex( index ){
      this.tabIndex = index
    },
    clickTab(){
      this.setSelectedBlackout(null)
    },
    getBlackouts(){
      this.$emit('reload-blackouts')
    }
  },
};
</script>
<style scoped>
.nav-item.active.tab-active-class {
  background-color: red;
}
</style>